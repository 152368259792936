/* eslint-disable no-console */
/* eslint-disable react/no-unknown-property */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import moment from 'moment';
import {NotificationWrapper, TitleContainer, TabName, TodaySection, Top, 
  Content, Container, LeftSection, Img, Recent, UpcomingBirthday,
  RightSection, Button, UpcomingSection, RecentSection, Right, StyledModal, ScrollStyles, BannerNew
} from './styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAmigoBirthday, getWorkAnniversary } from '../../redux/actions';
import { ImageUrl } from '../../utils/constants';
import {withTranslation} from 'react-i18next';
class BirthdaySidebarModal extends Component {

  constructor(){
    super();
    this.state = {
      activeTab : 0,
      imgPath: "../../../public/",
      buttonContent : "Wish Them!",
      anniversaryButton : "Shout Out!",
      currentMonth: null,
      tab:[]
    }
  }

  componentDidMount() {
    const { getAmigoBirthday, getWorkAnniversary, index,isAnniversaryCard, isBirthdayCard } = this.props;
    if(isAnniversaryCard && isBirthdayCard){
      this.setState({tab:[
        {
          tabName : "Birthday",
          imgPath : "/images/social/birthdayBanner.png",
          buttonText : "Wish Them"
        },
        {
          tabName : "Work Anniversary",
          imgPath : "/images/social/anniversaryBanner.png",
          buttonText : "Shout Out!"
        }
      ]})
    }else if(isAnniversaryCard){
      this.setState({tab:[
        {
          tabName : "Work Anniversary",
          imgPath : "/images/social/anniversaryBanner.png",
          buttonText : "Shout Out!"
        }
      ]})

    }else if(isBirthdayCard){
      this.setState({tab:[
        {
          tabName : "Birthday",
          imgPath : "/images/social/birthdayBanner.png",
          buttonText : "Wish Them"
        }
      ]})

    }
    let current = new Date();
    let month = current.getMonth();
    if(month===0){
      month = "January"
    }else if(month===1){
      month = "Feburary"
    }else if(month===2){
      month = "March"
    }else if(month===3){
      month = "April"
    }else if(month===4){
      month = "May"
    }else if(month===5){
      month = "June"
    }else if(month===6){
      month = "July"
    }else if(month===7){
      month = "August"
    }else if(month===8){
      month = "September"
    }else if(month===9){
      month = "October"
    }else if(month===10){
      month = "November"
    }else if(month===11){
      month = "December"
    }

    this.setState({
      currentMonth: month
    })
    getAmigoBirthday();
    getWorkAnniversary();
    this.setState({
      activeTab : index
    })
    if(index===1){
      this.setState({
        imgPath : "/images/social/anniversaryBanner.png",
        buttonContent : "Wish Them!"
      })
    }
    else{
      this.setState({
        imgPath : "/images/social/birthdayBanner.png",
      })
    }
    // eslint-disable-next-line no-undef
  }

  handleTab = ( data,index ) => {
    this.setState({
      activeTab : index,
      imgPath : data.imgPath,
      buttonContent : data.buttonText
    })
  }

  handleRequest = (data) => {
    const { peerCallBack } = this.props;
    const { activeTab } = this.state;
    peerCallBack( data, activeTab )
  }
  render() {
    const { birthdayList, anniversaryList, showModal, closeModal,t} = this.props;
    const { buttonContent, currentMonth, anniversaryButton, tab} = this.state;
    // let tab = [
    //   {
    //     tabName : t("Birthday"),
    //     imgPath : "/images/social/birthdayBanner.png",
    //     buttonText : t("Wish Them")
    //   },
    //   {
    //     tabName : t("Work Anniversary"),
    //     imgPath : "/images/social/anniversaryBanner.png",
    //     buttonText : t(anniversaryButton)
    //   }
    // ]
    localStorage.setItem("showBirthdaySidebar", 0);
    return (
      <StyledModal show={showModal} onHide={() => closeModal()}>
        <NotificationWrapper id={'notificationPopup'}>
          <TitleContainer>
            {
              tab && tab.map((data, index) => (
                <TabName style={{width:"auto"}} key={index} index={index} active={ this.state.activeTab === index } onClick={()=>this.handleTab(data,index)}>{t(data.tabName)}</TabName>
              ))
            }
          </TitleContainer>
          <div className={'birthdayContainer'} show={this.state.activeTab}>
            <BannerNew>
              <img src={this.state.imgPath} />
              <div className="currentMonth">{currentMonth}</div>
            </BannerNew>
            {
              this.state.activeTab===0 ? (
                (birthdayList && (birthdayList.today_birthday && birthdayList.today_birthday.length>0) || (birthdayList.upcoming_birthday && birthdayList.upcoming_birthday.length>0) || (birthdayList.recent_birthday && birthdayList.recent_birthday.length>0)) ? (
                  <ScrollStyles>
                    <TodaySection>
                      {
                        birthdayList && birthdayList.today_birthday && birthdayList.today_birthday.length>0 ?
                          (
                            <div>
                              <Top>
                                {t("Today")}
                              </Top>

                              {
                                birthdayList.today_birthday.map((data, index) => (
                                  <Container key={index}>
                                    <LeftSection>
                                      <Img>
                                        <img src={`${ImageUrl}/${data.profile_image}`}/>
                                      </Img>
                                      <Content>
                                        <div>
                                          {t(data.name)}
                                        </div>
                                        <div>
                                          {moment(data.birthday).format('MMM DD')}</div>
                                      </Content>
                                    </LeftSection>
                                    {data.show_recognition ? <Right>
                                      <RightSection>
                                        <Button onClick={()=>this.handleRequest(data)}>{t(buttonContent)}</Button>
                                      </RightSection>
                                    </Right> : null}
                                  </Container>
                                ))
                              }
                            </div>
                          )
                          : null
                      }
                    </TodaySection>

                    <UpcomingSection >
                      {
                        birthdayList && birthdayList.upcoming_birthday && birthdayList.upcoming_birthday.length>0 ? (
                          <div>
                            <UpcomingBirthday>
                              {t("Upcoming Birthdays")}
                            </UpcomingBirthday>
                            {
                              birthdayList.upcoming_birthday.map((data, index) => (
                                <Container key={index}>
                                  <LeftSection>
                                    <Img>
                                      <img src={`${ImageUrl}/${data.profile_image}`}/>
                                    </Img>
                                    <Content>
                                      <div>
                                        {t(data.name)}
                                      </div>
                                      <div>
                                        {moment(data.birthday).format('MMM DD')}</div>
                                    </Content>
                                  </LeftSection>
                                  {data.show_recognition ? <Right>
                                    <RightSection>
                                      <Button onClick={()=>this.handleRequest(data)}>{t(buttonContent)}</Button>
                                    </RightSection>
                                  </Right> : null}
                                </Container>
                              ))
                            }
                          </div>
                        ) : null }
                    </UpcomingSection>
                    <RecentSection >
                      {
                        birthdayList && birthdayList.recent_birthday && birthdayList.recent_birthday.length>0 ? (
                          <div>
                            <Recent>
                              {t("Recent Birthdays")}
                            </Recent>
                            {birthdayList.recent_birthday.map((data, index) => (
                              <Container key={index}>
                                <LeftSection>
                                  <Img>
                                    <img src={`${ImageUrl}/${data.profile_image}`}/>
                                  </Img>
                                  <Content>
                                    <div>
                                      {t(data.name)}
                                    </div>
                                    <div>
                                      {moment(data.birthday).format('MM DD')}
                                    </div>
                                  </Content>
                                </LeftSection>
                                {data.show_recognition ? <Right>
                                  <RightSection>
                                    <Button onClick={()=>this.handleRequest(data)}>{t(buttonContent)}</Button>
                                  </RightSection>
                                </Right> : null}
                              </Container>
                            )) }
                          </div>) : null
                      }
                    </RecentSection>
                  </ScrollStyles>
                ) :
                  (
                    <div className="noBirthday">{t("No Birthday to display")}</div>
                  )
              ) : (
                anniversaryList && (( anniversaryList?.today_anniversary?.length>0) || ( anniversaryList?.upcoming_anniversary?.length>0) || (anniversaryList?.recent_anniversary && anniversaryList?.recent_anniversary?.length>0)) ? (
                  <ScrollStyles>
                    <TodaySection>
                      {
                        anniversaryList &&  anniversaryList?.today_anniversary?.length>0 ?
                          (
                            <div>
                              <Top>
                                {t("Today")}
                              </Top>

                              {
                                anniversaryList?.today_anniversary?.slice(0,4).map((data, index) => (
                                  <Container key={index}>
                                    <LeftSection>
                                      <Img>
                                        <img src={`${ImageUrl}/${data.profile_image}`}/>
                                      </Img>
                                      <Content>
                                        <div>
                                          {t(data.name)}
                                        </div>
                                        <div>
                                          {moment(data.work_anniversary).format('MMM DD')}</div>
                                      </Content>
                                    </LeftSection>
                                    {data.show_recognition ? <Right>
                                      <RightSection>
                                        <Button onClick={()=>this.handleRequest(data)}>{t(anniversaryButton)}</Button>
                                      </RightSection>
                                    </Right> : null}
                                  </Container>
                                ))
                              }
                            </div>
                          )
                          : null
                      }
                    </TodaySection>

                    <UpcomingSection >
                      {
                        anniversaryList && anniversaryList.upcoming_anniversary && anniversaryList.upcoming_anniversary.length>0 ? (
                          <div>
                            <UpcomingBirthday>
                              {t("Upcoming Anniversary")}
                            </UpcomingBirthday>
                            {
                              anniversaryList.upcoming_anniversary.slice(0,4).map((data, index) => (
                                <Container key={index}>
                                  <LeftSection>
                                    <Img>
                                      <img src={`${ImageUrl}/${data.profile_image}`}/>
                                    </Img>
                                    <Content>
                                      <div>
                                        {t(data.name)}
                                      </div>
                                      <div>
                                        {moment(data.work_anniversary).format('MMM DD, YYYY')}</div>
                                    </Content>
                                  </LeftSection>
                                  {data.show_recognition ? <Right>
                                    <RightSection>
                                      <Button onClick={()=>this.handleRequest(data)}>{t(anniversaryButton)}</Button>
                                    </RightSection>
                                  </Right> : null}
                                </Container>
                              ))
                            }
                          </div>
                        ) : null }
                    </UpcomingSection>
                    <RecentSection >
                      {
                        anniversaryList && anniversaryList.recent_anniversary && anniversaryList.recent_anniversary.length>0 ? (
                          <div>
                            <Recent>
                              {t("Recent Anniversary")}
                            </Recent>
                            {anniversaryList.recent_anniversary.slice(0,4).map((data, index) => (
                              <Container key={index}>
                                <LeftSection>
                                  <Img>
                                    <img src={`${ImageUrl}/${data.profile_image}`}/>
                                  </Img>
                                  <Content>
                                    <div>
                                      {t(data.name)}
                                    </div>
                                    <div>
                                      {moment(data.work_anniversary).format('MMM DD')}
                                    </div>
                                  </Content>
                                </LeftSection>
                                {data.show_recognition ? <Right>
                                  <RightSection>
                                    <Button onClick={()=>this.handleRequest(data)}>{t(anniversaryButton)}</Button>
                                  </RightSection>
                                </Right> : null}
                              </Container>
                            )) }
                          </div>) : null
                      }
                    </RecentSection>
                  </ScrollStyles>
                ) :
                  (
                    <div className="noAnniversary">{t("No Anniversary to display")}</div>
                  )
              )
            }
          </div>
        </NotificationWrapper>
      </StyledModal>
    )
  }
}

BirthdaySidebarModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  getAmigoBirthday : PropTypes.func,
  birthdayList : PropTypes.object,
  getWorkAnniversary : PropTypes.func,
  peerCallBack: PropTypes.func,
  anniversaryList: PropTypes.object,
  index : PropTypes.number,
  t: PropTypes.func,
  isAnniversaryCard:PropTypes.number,
  isBirthdayCard:PropTypes.number,
}  

const mapStateToProps = (state) => ({
  birthdayList : state.social.birthdayList,
  anniversaryList : state.profileData.workAnniversaryList,
})

const mapDispatchToProps = (dispatch) => ({
  getAmigoBirthday: () => dispatch(getAmigoBirthday()),
  getWorkAnniversary: () => dispatch(getWorkAnniversary())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(BirthdaySidebarModal)));
